/* eslint-disable max-len */
import Vue from 'vue';

import './Contact.scss';
import { ApolloError, gql } from 'apollo-boost';
import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';
import Button from '@/components/Admin/Button/Button';
import apollo from '@/services/apollo';

const Contact = Vue.extend({
  data() {
    return {
      formData: {
        email: '',
        message: '',
        name: '',
        phone: '',
        title: '',
      },
      formError: '',
      formMessage: '',
      loading: false,
    };
  },

  methods: {
    sendMessage(event: Event) {
      event.preventDefault();
      this.formError = '';
      this.formMessage = '';

      const mutation = gql`
        mutation sendMessage(
          $email: String!
          $name: String!
          $phone: String!
          $message: String!
          $title: String!
        ) {
          sendMessage(
            email: $email
            name: $name
            phone: $phone
            message: $message
            title: $title
          ) {
            ok
          }
        }
      `;

      this.loading = true;
      apollo.mutate({
        mutation,
        variables: this.formData,
      })
        .then(() => {
          this.formMessage = 'Message sent! We will respond soon';
        })
        .catch((err: ApolloError) => {
          this.formError = err.graphQLErrors.map(({ message }) => message).join(' ');
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  render() {
    return (
      <div class="public-booking">
        <ClientNav />

        <div class="public-booking-container">

          <div class="public-booking__image">
            <img
              alt="Booking image"
              src="https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663536/Teefal%20Studios/BgUnicorn.jpg"
            />
          </div>

          <div>
            <h1>Contact Us</h1>

            {/* <p style={{
              maxWidth: '480px',
            }}>
              We are always happy to hear from you.
              </p> */}

            <form onSubmit={(event: Event) => this.sendMessage(event)}>

              <div class="form-group">
                <label for="name">Name</label>
                <input name="name" type="text" v-model={this.formData.name} required />
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="email" name="email" v-model={this.formData.email} required />
              </div>

              <div class="form-group">
                <label for="phone">Phone</label>
                <input type="text" name="phone" v-model={this.formData.phone} required />
              </div>

              <div class="form-group">
                <label for="title">Title</label>
                <input type="text" name="title" v-model={this.formData.title} />
              </div>

              <div class="form-group">
                <label for="message">Message</label>
                <textarea name="message" id="message" cols="30" rows="4" v-model={this.formData.message}></textarea>
              </div>

              {
                this.formError && (
                  <div class="form-group">
                    <div class="form-error">{this.formError}</div>
                  </div>
                )
              }

              {
                this.formMessage && (
                  <div class="form-group">
                    <div class="form-success">{this.formMessage}</div>
                  </div>
                )
              }

              <div class="form-group">
                <Button
                  loading={this.loading}
                  primary
                  type='submit'
                >
                  Send Message
              </Button>
              </div>
            </form>
          </div>

        </div>

        <Footer />
      </div>
    );
  },
});

export default Contact;

/* eslint-disable max-len */
import { VNode } from 'vue';

import './Home.scss';
import Footer from '@/components/Shared/Footer/Footer';

const HomeHeader = () => (
  <header class='header'>
    <div class='textContainer'>
      <h1 class='title'>Teefal Studios</h1>
      <p class='tagline'>Creating memories for a lifetime</p>

      <router-link to="/booking" class="cta">Book Now</router-link>
    </div>
  </header>
);

const packages = [
  {
    id: 0,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663709/Teefal%20Studios/3O2A2531.jpg',
    title: 'Maternity',
  },
  {
    id: 1,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663721/Teefal%20Studios/JA7A.jpg',
    title: 'Newborn',
  },
  {
    id: 2,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663744/Teefal%20Studios/3O2A9209.jpg',
    title: 'Sitter',
  },
  {
    id: 3,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663803/Teefal%20Studios/3O2A9658.jpg',
    title: 'One Year',
  },
  {
    id: 4,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663819/Teefal%20Studios/3O2A3463.jpg',
    title: 'Age 2 & Up',
  },
  {
    id: 5,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663852/Teefal%20Studios/3O2A9424.jpg',
    title: 'Family',
  },
  {
    id: 6,
    image: 'https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663944/Teefal%20Studios/redchaircopy.jpg',
    title: 'Adult',
  },
  // {
  //   id: 7,
  //   image: '',
  //   title: 'Birthday',
  // },
  // {
  //   id: 8,
  //   image: '',
  //   title: 'Pre-wedding Shoots',
  // },
  // {
  //   id: 9,
  //   image: '',
  //   title: 'Weddings & Annivesaries',
  // },
];

export default {

  name: 'home',

  render(): VNode {
    return (
      <div class='home'>
        <HomeHeader />

        <section class="homepage-sections">
          <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663237/Teefal%20Studios/section-1.png' class="lazyload homepage-section__image" alt="Section 1" />

          <router-link class="homepage-section" to="/about">
            <div class="homepage-section__content">
              <span class="homepage-section__tag">About</span>
              <h3 class="homepage-section__title">TALENT &amp; HARDWORK MEETS INNOVATION</h3>
              <span class="homepage-section__description">We’re a team of talented, artistic photographers who work to offer families and couples the very best in timeless photoshoots for weddings, maternity, newborn, cake smash and family photo sessions in Nigeria. </span>
            </div>
          </router-link>

          <div class="homepage-section">
            <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663243/Teefal%20Studios/section-2.png' class="lazyload homepage-section__image" alt="Section 2" />
          </div>

          <div class="homepage-section" to="/services">
            <div class="homepage-section__content">
              <span class="homepage-section__tag">SERVICES</span>
              <h3 class="homepage-section__title">ALL YOUR PHOTOGRAPHY NEEDS</h3>
              <span class="homepage-section__description">Our services include shoots for maternity, newborn, family shoots, pre-wedding shoots, wedding &amp; engagements</span>
            </div>
          </div>

          <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663244/Teefal%20Studios/section-3.png' class="lazyload homepage-section__image" alt="Section 3" />

          <div class="homepage-section" to="/portfolio">
            <div class="homepage-section__content">
              <span class="homepage-section__tag">PORTFOLIO</span>
              <h3 class="homepage-section__title">CAPTURE EXPERIENCES </h3>
              <span class="homepage-section__description">With extreme attention to detail, we help preserve your best memories with exquisite photographs</span>
            </div>
          </div>
        </section>

        <section class="homepage-packages">

          <div to="/booking" class="homepage-package__title ">
            <h2>Our Services</h2>
          </div>

          {
            packages.map(item => (
              <router-link to="/booking" class="homepage-package">
                <img data-src={item.image} class="homepage-package__image lazyload" />
                <div class="homepage-package__meta-background"></div>
                <div class="homepage-package__meta">
                  <span>{item.title}</span>
                </div>
              </router-link>
            ))
          }

        </section>

        <section class="homepage-story">
          <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582664106/Teefal%20Studios/3O2A9218.jpg' class="lazyload homepage-section__image" alt="Our story" />

          <div>
            <router-link to="/story" class="homepage-section__content">
              <h2 class="homepage-section__title">Story</h2>
              <p class="homepage-section__description">We’re a team of talented, artistic photographers who work  to offer families and couples the very best in timeless photoshoots for weddings, maternity, newborn, cake smash and family photo sessions in Nigeria.</p>

              <span class="homepage-section__tag">READ THE STORY</span>
            </router-link>
          </div>
        </section>

        <Footer />
      </div>
    );
  },
};
